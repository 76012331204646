<template>
  <div id="app">
    <el-header style="width: 100%; height: 80%">
      <div class="container">
        <a href="#" class="logo">
          <img :src="logoSrc" alt="Logo" />
        </a>
        <nav style="padding: 14px">
          <ul id="navigation">
            <li v-for="(item, index) in this.homeData" :key="index">
              <a href="#" @click="tiaoz(item.id, item.url)">{{ item.name }}</a>
            </li>
            <!--  <li class="menu"><a href="#/about">	</a></li>
            <li class="menu"><a href="#/services">餐饮服务</a></li>
            <li class="menu"><a href="#/news">动态中心</a></li>
            <li class="menu"><a href="#/honors">企业荣誉</a></li>
            <li class="menu"><a href="#/contact">联系我们</a></li> -->
          </ul>
        </nav>
      </div>
    </el-header>
    <router-view />
    <el-footer>
      <div class="footer">
        <div class="hfe-copyright-wrapper">
          <span>
            <a
              v-for="(item, index) in this.homeData"
              :key="index"
              :href="'#'"
              @click="tiaoz(item.id, item.url)"
              style="margin-right: 1em; margin-right: 1em; color: #adadad"
              >{{ item.name }} |
            </a>
            <br />
            <p>{{ contentmsg }}</p>
          </span>
        </div>
        <div class="beian">
          <div class="beian1">
            <a href="http://beian.miit.gov.cn/">
              <span style="color: #adadad">{{ recordNumber }}</span>
            </a>
          </div>
        </div>
      </div>
    </el-footer>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      logoSrc: "/manyu.png",
      gyImgSrc: "/qt.jpg",
      foodImageSrc: "/sw.jpg",
      msg: "文字yi",
      homeData: {},
      recordNumber: "备案号：粤ICP备2021008323号-1",
      contentmsg:
        "© 2003-" +
        new Date().getFullYear() +
        " 佛山市满誉餐饮服务有限公司 版权所有",
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    getlist() {
      axios({
        method: "get",
        url: this.$globalData.url,
      })
        .then((Response) => {
          this.homeData = Response.data.data;
        })
        .catch((Error) => {
          console.log(Error);
        });
    },
    tiaoz(id, name) {
      this.$router.push(
        { name: name, params: { id } },
        () => {},
        () => {}
      );
    },
  },
};
</script>
<style>
.beian {
  margin-left: 10%;
}
.beian1 a {
  text-decoration: none;
}
.footer {
  width: 100%;
  height: 100px;
  background-color: #000000;
  margin-bottom: 20px;
  display: flex;
  display: flex;
  align-items: center; /* 垂直居中 */
}
.hfe-copyright-wrapper {
  margin-left: 24%;
  text-decoration: none;
  color: #adadad;
}
.hfe-copyright-wrapper span p {
  float: left;
}
.hfe-copyright-wrapper a {
  text-decoration: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  margin: 0 auto;
  max-width: 940px;
  min-height: 60px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
nav ul {
  display: inline-block;
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  margin: 0;
  padding: 0;
}

nav ul li a {
  color: #000000;
  display: block;
  font-family: Arial;
  font-size: 18px;
  font-weight: normal;
  line-height: 59px;
  margin: 0;
  min-height: 60px;
  padding: 0 24px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

nav ul li a:hover {
  background-color: #620031;
  color: #ffffff;
}

nav ul .selected a {
  background-color: #000000;
  color: #a3a3a3;
}

nav ul .menu {
  position: relative;
}

.logo {
  float: left;
  height: 40px;
  margin: 0px 160px 0 -120px;
  padding: 0;
}
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
