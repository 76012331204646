import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
Vue.config.productionTip = false;
Vue.use(ElementUI);


Vue.prototype.$globalData = {
  url:"index.php/api/index"
};
// 创建Vue实例等其他配置...
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
