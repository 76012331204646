import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "index",
        component: () => import("../views/index.vue"),
    },

    {
        path: "/about",
        name: "about",
        component: () => import("../views/about.vue"),
        hidden: true,
    },
    {
        path: "/services",
        name: "services",
        component: () => import("../views/services.vue"),
        hidden: true,
    },
    {
        path: "/news",
        name: "news",
        component: () => import("../views/news.vue"),
        hidden: true,
    },
    {
        path: "/mcontact",
        name: "mcontact",
        component: () => import("../views/mcontact.vue"),
        hidden: true,
    },
    {
        path: "/honors",
        name: "honors",
        component: () => import("../views/honors.vue"),
        hidden: true,
    },{
        path: "/mdetails",
        name: "mdetails",
        component: () => import("../views/mdetails.vue"),
        hidden: true,
    },
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});

export default router;
